import './App.css';

function App() {
  return (
    <div className="App">
      <div className="hero-container">
        <img
          src="/dani.png"
          alt="Dani"
          className="hero-image"
        />
        <div className="content-overlay">
          <h1>
            <a
              href="https://raydium.io/swap/?inputMint=sol&outputMint=8oyxbtm1aT1BUNcoMua4rgycuyrc3cPc6iE7sRsypump"
              style={{ color: 'white', textDecoration: 'none' }}
            >
              areyoucoming?
            </a>
          </h1>
        </div>
      </div>
    </div>
  );
}

export default App;
